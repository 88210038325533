import React, { useState, useEffect } from "react"
import posed from "react-pose"
import styled from "styled-components"
import Layout from "../layout"
import Container from "../Container"
import Header from "../header/header"
import Footer from "../footer/footer"
import { Link } from "gatsby"
import BlogListItem from "./BlogListItem"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SingleCategory = ({ pageContext: { name, posts } }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  return (
    <Layout>
      <Header />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <Container>
          <PageTitle>
            <h3>{name}</h3>
          </PageTitle>
          <WorkListWrapper>
            {posts.nodes.map((item) => (
              <BlogListItem item={item} key={item.id} />
            ))}
          </WorkListWrapper>
        </Container>
        <BlogAllLink>
          <Link to="/newsroom">Back to Newsroom</Link>
        </BlogAllLink>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default SingleCategory

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const WorkListWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${({ theme }) => theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`
const PageTitle = styled.div`
  margin-top: 80px;
`
const BlogAllLink = styled.div`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.gold};
  padding: 40px;
  a {
    background-color: ${({ theme }) => theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${({ theme }) => theme.dark};
      color: ${({ theme }) => theme.white};
      transform: scale(1.05);
    }
  }
`
